<template>
  <vx-card title="Promotion">

    <div class="tab-text">
      <div class="vx-col sm:w-1/3 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Promotion Code</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
                v-model="promotionSelect.selected"
                :options="promotionSelect.options"
                :searchable="false"
                :showNoOptions="false"
                :max-height="125"
                :allow-empty="false"
                :show-labels="false"
                v-validate="'required'"
              >
              <span
                class="text-danger text-sm"
                v-if="promotionSelect.selected == null"
                >This field is required</span
              >
                <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                    <span class="option__title">{{ props.option.Name }} ({{ props.option.Code }})</span>
                </span>
                </template>

                <template slot="option" slot-scope="props">
                <div class="option__desc">
                    <span class="option__title">{{ props.option.Name }} ({{ props.option.Code }})</span>
                </div>
                </template>

              </multiselect>
          </div>
        </div>
      </div>
    </div>

    <vs-tabs :value="active" v-if="view==true">
      <vs-tab label="Special Price">
        <div class="tab-text">
          <special-price-component 
          @tab="setTab"
          :id="promotionSelect.selected.ID"
          >
          </special-price-component>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import SpecialPriceComponent from "./special_price";
export default {
  components: {
    SpecialPriceComponent,
  },
  data() {
    return {
      active: 0,
      view : false,
      promotion: {
        budget: "",
        sales: "",
        marketing: "",
        principal: "",
      },
      promotionSelect: {
          selected: null,
          options: [],
      },
      params: {
        search: "",
        length: 5,
        page: 1,
        order: "asc",
        sort: "sales_orders.code",
      },
      recordsTotal: 0,
    };
  },
  mounted() {
    this.getPromotion();
  },
  watch: {
    "promotionSelect.selected"(){
      this.view = true;
    }
  },
  methods: {
    setTab(val) {
      switch (val) {
        case "Special Price":
          this.active = 1;
          break;
        default:
          this.active = 0;
          break;
      }
    },
    getPromotion() {
      this.$vs.loading();
      this.$http
        .get("api/v1/promotion/data-table", {
          params: {
            search: "",
            length: 100,
            page: 1,
            order: "asc",
            sort: "code",
            status: 3,
            promotion_type: "Special Price"
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            for(var promo = 0;promo<resp.data.promotions.length;promo++){
              this.promotionSelect.options.push(resp.data.promotions[promo].promotion)
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    formatPrice(angka, prefix = "") {
      var number_string = angka.replace(/[^,\d]/g, "").toString();
      var split = number_string.split(",");
      var sisa = split[0].length % 3;
      var rupiah = split[0].substr(0, sisa);
      var ribuan = split[0].substr(sisa).match(/\d{3}/gi);
      var separator = "";
      if (ribuan) {
        if (sisa) {
          separator = ".";
        } else {
          separator = "";
        }
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return prefix == undefined ? rupiah : rupiah ? "" + rupiah : "";
    },
    isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();
        } else {
            return true;
        }
    },
  }
};
</script>