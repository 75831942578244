<template>
    <div>
        <div class="mb-12">
            <data-table
              :responseData="responseDataSO"
              :propsParams="paramsSO"
              :header="header"
              @reloadDataFromChild="reloadDataSO"
            >
              <template slot="tbody">
                
                <vs-tr :key="indextr" v-for="(tr, indextr) in responseDataSO.report">
                  <vs-td>
                    {{tr.code}}
                  </vs-td>
                  <vs-td>
                    {{dateFormat(tr.date)}}
                  </vs-td>
                  <vs-td>
                    {{status(tr.is_closed)}}
                  </vs-td>
                  <vs-td>
                    {{tr.customer_name}}
                  </vs-td>
                  <vs-td>
                    {{tr.item_name}} ({{tr.item_unit}})
                  </vs-td>
                  <vs-td>
                    {{tr.quantity}}
                  </vs-td>
                  <vs-td>
                    {{priceFormat(tr.subtotal)}}                    
                  </vs-td>
                  <vs-td>
                    {{priceFormat(tr.subtotal - tr.discount)}}                    
                  </vs-td>
                  <vs-td>
                    {{priceFormat(tr.subtotal - (tr.subtotal - tr.discount))}}            
                  </vs-td>
                </vs-tr>
              </template>
            </data-table>
          </div>
          
      </div>
</template>


<script>

import moment from "moment";

    export default {
        components: {
        },
        props: {
          id: Number,
        },
        data() {
            return {
              paramsSO: {
                search: "",
                length: 10,
                page: 1,
                order: "asc",
                sort: "sales_orders.code",
              },
              header: [
                {
                  text: "SO Code",
                  value: "code",
                },
                {
                  text: "SO Date",
                  value: "so_date", 
                },
                {
                  text: "SO Status",
                  value: "so_status",
                },
                {
                  text: "Customer",
                  value: "customer",
                },
                {
                  text: "Item",
                  value: "item",
                },
                {
                  text: "Qty",
                  value: "qty",
                },
                {
                  text: "Total List Price",
                  value: "total_list_price",
                },
                {
                  text: "Total Net Price",
                  value: "total_net_price",
                },
                {
                  text: "Total Promo",
                  value: "total_promo",
                },
              ],
              selectedData: {},
              responseDataSO: {},
            };
        },
        methods: {
          dateFormat(value) {
            return moment(String(value)).format("DD/MM/YYYY");
          },
          status(value) {
            if (value == 1){
              return "Closed"
            }else{
              return "Open"
            }
          },
          reloadDataSO(params) {
            this.params = params;
            this.$vs.loading();
            this.$http
              .get("api/v1/report/report-promotion", {
                params: {
                  search: params.search,
                  length: params.length,
                  page: params.page,
                  order: params.order,
                  sort: params.sort,
                  id: this.id
                }
              })
              .then(
                resp => {
                  if (resp.code == 500) {
                    this.$vs.loading.close();
                  } else if(resp.code == 200) {
                    this.$vs.loading.close();
                    this.responseDataSO = resp.data;
                    this.recordsTotal = resp.data.recordsTotal;
                    this.length = resp.data.length;
                  }
                  else {
                    this.$vs.loading.close();
                  }
                }
              );
          },
        }, 
        mounted() {
            this.reloadDataSO(this.paramsSO)
        },
        watch: {
          "id"(){
            this.reloadDataSO(this.paramsSO)
          }
        },
        computed: {
            
        },
    };
</script>


<style scoped>
    .nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 50%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.red {
  background: red;
  background-color: red;
}
.yellow {
  background: yellow;
  background-color: yellow;
}
.display-none {
  display: none;
}
</style>