<template>
  <div v-if="responseData">
    
    <div class="vx-col sm:w-5/6 w-full">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Promotion Budget</span>
          </div>
          <div class="vx-col sm:w-1/5 w-full">
            <vx-input-group>
              <template slot="prepend">
                <div class="prepend-text bg-primary">
                  <span>Rp</span>
                </div>
              </template>
              <vs-input
                type="text"
                :disabled="true"
                v-model="budgetValue"
              />
            </vx-input-group>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Distribution</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Sales</span>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/4 w-full">
                <vx-input-group>
                  <vs-input
                    :disabled="true"
                    v-model="percentageSales" 
                    />   
                  <template slot="append">
                    <div class="append-text bg-primary">
                      <span>%</span>
                    </div>
                  </template>
                </vx-input-group>
              </div>

              <div class="vx-col sm:w-1/2 w-full">
                <vx-input-group>
                  <template slot="prepend">
                    <div class="prepend-text bg-primary">
                      <span>Rp</span>
                    </div>
                  </template>
                  <vs-input
                    type="text"
                    :disabled="true"
                    v-model="amountSales"
                  />
                </vx-input-group>
              </div>
            </div>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Marketing</span>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/4 w-full">
                <vx-input-group>
                  <vs-input
                    :disabled="true"
                    v-model="percentageMarketing" 
                    />   
                  <template slot="append">
                    <div class="append-text bg-primary">
                      <span>%</span>
                    </div>
                  </template>
                </vx-input-group>
              </div>

              <div class="vx-col sm:w-1/2 w-full">
                <vx-input-group>
                  <template slot="prepend">
                    <div class="prepend-text bg-primary">
                      <span>Rp</span>
                    </div>
                  </template>
                  <vs-input
                    type="text"
                    :disabled="true"
                    v-model="amountMarketing"
                  />
                </vx-input-group>
              </div>
            </div>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Principal</span>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/4 w-full">
                <vx-input-group>
                  <vs-input
                    :disabled="true"
                    v-model="percentagePrincipal" 
                    />   
                  <template slot="append">
                    <div class="append-text bg-primary">
                      <span>%</span>
                    </div>
                  </template>
                </vx-input-group>
              </div>

              <div class="vx-col sm:w-1/2 w-full">
                <vx-input-group>
                  <template slot="prepend">
                    <div class="prepend-text bg-primary">
                      <span>Rp</span>
                    </div>
                  </template>
                  <vs-input
                    type="text"
                    :disabled="true"
                    v-model="amountPrincipal"
                  />
                </vx-input-group>
              </div>
            </div>
          </div>
        </div>

        
    </div>

    <div class="vx-row  sm:w-1/2">
      <table class="table">
          <tr>
            <td>Promo Code</td>
            <td>{{responseData.promotion.Code}}</td>
          </tr>
          <tr>
            <td>Promo Model</td>
            <td>{{responseData.promotion.PromotionType}}</td>
          </tr>
          <tr>
            <td>Validity</td>
            <td>{{dateFormat(responseData.promotion.ValidFrom)}} - {{dateFormat(responseData.promotion.ValidTo)}}</td>
          </tr>
          <!-- <tr>
            <td>Status</td>
            <td></td>
          </tr> -->
          <tr>
            <td>Budget Value</td>
            <td>{{priceFormat(formatAngka(budgetValue))}}</td>
          </tr>
          <tr>
            <td>Budget Used</td>
            <td>{{priceFormat(totalPromo)}}</td>
          </tr>
          <tr>
            <td>Budget Un-used</td>
            <td>{{priceFormat(formatAngka(budgetValue) - totalPromo)}}</td>
          </tr>
      </table>
    </div>
    
    <br>

    <div class="vx-row mb-12 w-full">
      <div class="vx-col sm:w-1/2">
        <table class="table">
            <tr>
              <td></td>
              <td>Sales</td>
              <td>Marketing</td>
              <td>Principal</td>
            </tr>
            <tr>
              <td>Budget Distribution</td>
              <td>{{priceFormat(amountSales)}}</td>
              <td>{{priceFormat(amountMarketing)}}</td>
              <td>{{priceFormat(amountPrincipal)}}</td>
            </tr>
            <tr>
              <td>Budget Used</td>
              <td>{{priceFormat(percentageSales * totalPromo / 100)}}</td>
              <td>{{priceFormat(percentageMarketing * totalPromo / 100)}}</td>
              <td>{{priceFormat(percentagePrincipal * totalPromo / 100)}}</td>
            </tr>
            <tr>
              <td>Budget un-used</td>
              <td>{{priceFormat(amountSales - (percentageSales * totalPromo / 100))}}</td>
              <td>{{priceFormat(amountMarketing - (percentageMarketing * totalPromo / 100))}}</td>
              <td>{{priceFormat(amountPrincipal - (percentagePrincipal * totalPromo / 100))}}</td>
            </tr>
        </table>
      </div>
      <div class="vx-col  sm:w-1/2">
         
        <span>
          <h4 class="mb-5">Price Rule</h4>
        </span>
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.price_rule"
            >
              <vs-td>
                {{ tr.item_name }} ({{tr.item_unit}})
              </vs-td>
              <vs-td>
                {{ tr.price }}
              </vs-td>
              <vs-td>
                {{ tr.discount }}
              </vs-td>
              <vs-td>
                {{ tr.price - tr.discount }}
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
    </div>
          
    <list  :id="id"></list>
  </div>
</template>


<script>
import list from "./list.vue";
import moment from "moment";

    export default {
        components: {
          list,
        },
        props: {
          id: Number,
        },
        data() {
            return {
              params: {
                search: "",
                length: 5,
                page: 1,
                order: "asc",
                sort: "item_name",
              },
              header: [
                {
                  text: "Item",
                  value: "item",
                },
                {
                  text: "List Price",
                  value: "list_price",
                },
                {
                  text: "Discount",
                  value: "discount",
                },
                {
                  text: "Net",
                  value: "net",
                },
              ],
              selectedData: {},
              responseData: {},
              totalPromo : 0,
              percentageSales : 0,
              percentageMarketing : 0,
              percentagePrincipal : 0,
              amountSales: 0,
              amountMarketing: 0,
              amountPrincipal: 0,
              budgetValue: 0,
            };
        },
        methods: {
          dateFormat(value) {
            return moment(String(value)).format("DD/MM/YYYY");
          },
          formatPrice(angka, prefix = "") {
            var number_string = angka.toString().replace(/[^,\d]/g, "").toString();
            var split = number_string.split(",");
            var sisa = split[0].length % 3;
            var rupiah = split[0].substr(0, sisa);
            var ribuan = split[0].substr(sisa).match(/\d{3}/gi);
            var separator = "";
            if (ribuan) {
              if (sisa) {
                separator = ".";
              } else {
                separator = "";
              }
              rupiah += separator + ribuan.join(".");
            }
            rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
            return prefix == undefined ? rupiah : rupiah ? "" + rupiah : "";
          },
          formatAngka(angka) {
            var number_string = angka.toString().replace(/[^,\d]/g, "").toString();
            
            return number_string;
          },
          reloadData(params) {
            this.params = params;
            this.$vs.loading();
            this.$http
              .get("api/v1/report/report-promotion", {
                params: {
                  search: params.search,
                  length: params.length,
                  page: params.page,
                  order: params.order,
                  sort: params.sort,
                  id: this.id
                }
              })
              .then(
                resp => {
                  if (resp.code == 500) {
                    this.$vs.loading.close();
                  } else if(resp.code == 200) {
                    this.$vs.loading.close();
                    this.responseData = resp.data;
                    this.recordsTotal = resp.data.recordsTotalPrice;
                    this.length = resp.data.price_rule.length;
                    for (var i=0; i < this.responseData.report.length; i++){
                        this.totalPromo = this.totalPromo + (this.responseData.report[i].subtotal - (this.responseData.report[i].subtotal - this.responseData.report[i].discount))
                    }

                    this.budgetValue = this.responseData.promotion.BudgetValue

                    if(this.responseData.promotion_budget[0]){
                      if(this.responseData.promotion_budget[0].BudgetDepartment == "1"){
                        this.percentageMarketing = this.responseData.promotion_budget[0].Percentage
                        this.amountMarketing = this.responseData.promotion_budget[0].Amount
                      }else if (this.responseData.promotion_budget[0].BudgetDepartment == "2"){
                        this.percentageSales = this.responseData.promotion_budget[0].Percentage
                        this.amountSales = this.responseData.promotion_budget[0].Amount
                      }else if (this.responseData.promotion_budget[0].BudgetDepartment == "3"){
                        this.percentagePrincipal = this.responseData.promotion_budget[0].Percentage
                        this.amountPrincipal = this.responseData.promotion_budget[0].Amount
                      }
                      
                    }

                    if(this.responseData.promotion_budget[1]){
                      if (this.responseData.promotion_budget[1].BudgetDepartment == "2"){
                        this.percentageSales = this.responseData.promotion_budget[1].Percentage
                        this.amountSales = this.responseData.promotion_budget[1].Amount
                      }else if (this.responseData.promotion_budget[1].BudgetDepartment == "3"){
                        this.percentagePrincipal = this.responseData.promotion_budget[1].Percentage
                        this.amountPrincipal = this.responseData.promotion_budget[1].Amount
                      }
                    }

                    if(this.responseData.promotion_budget[2]){
                      this.percentagePrincipal = this.responseData.promotion_budget[2].Percentage
                      this.amountPrincipal = this.responseData.promotion_budget[2].Amount
                    }

                    console.log("marketing : " + this.amountMarketing)
                    console.log("sales : " + this.amountSales)
                    console.log("principal : " + this.amountPrincipal)
                  }
                  else {
                    this.$vs.loading.close();
                  }
                }
              );
          },
            
        },
        mounted() {
          this.reloadData(this.params)
        },
        watch: {
          "id"(){
            this.reloadData(this.params)
          }
        },
        computed: {
          
        },
    };
</script>


<style scoped>
    .nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 50%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.red {
  background: red;
  background-color: red;
}
.yellow {
  background: yellow;
  background-color: yellow;
}
.display-none {
  display: none;
}
</style>